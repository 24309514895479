import '../../service/domain/account.js';
import '../../service/configs/registration.js';
import '../../service/domain/system.js';
import '../../service/helper.js';
import '../../service/configs/config.js';
import '../../service/domain/modal.js';
import '../../service/domain/popup.js';
import '../../service/domain/auth-with-deposit.js';
import '../../service/domain/paymentWindow.js';

(function () {
  'use strict';

  const component = {
    name: 'authWithDepositRegistration',
  };

  controller.$inject = [
    '$scope',
    'account',
    'registration',
    'system',
    'helper',
    'config',
    'modal',
    'popup',
    'authWithDeposit',
    'paymentWindow',
    '$timeout'
  ];

  function controller(
    $scope,
    _account,
    _registration,
    _system,
    _helper,
    _config,
    _modal,
    _popup,
    _authWithDeposit,
    _paymentWindow,
    $timeout,
  ) {
    const vm = this;
    const shouldFetchCountries = _registration.config.countries;
    const cred = {};
    const preSavedData = _authWithDeposit.preSavedData().get();
    let countryOrCurrencyChanged = false;

    let formHasOwnRetype = false;

    $scope.preloader = false;

    $scope.page = 0;

    $scope.currencyList = [];
    $scope.countries = [];
    $scope.country = {};

    $scope.firstSubmit = true;

    $scope.backToPayments = () => {
      const parentScope = $scope.$parent.$parent;
      parentScope.paysystemStep = true;
      parentScope.hideRegistartion = true;
    };

    $scope.switchToLogin = () => {
      const parentScope = $scope.$parent.$parent;
      parentScope.registrationStep = false;
      parentScope.loginStep = true;
      parentScope.hasRememberedUser = true;
    };

    $scope.formData = {
      currency: preSavedData.currency,
      country: preSavedData.country,
      gender: 'M',
      accept: false,
      gdpr_data: false,
      gdpr_marketing: false,
      enable_trigger_codes: preSavedData.enable_trigger_codes,
      phone: '',
    };

    vm.$onInit = () => {
      _registration.addAvatarsToScope($scope);

      if (shouldFetchCountries !== false) {
        prepareCountriesAndCurrencies();
      }

      if (!_config.production) {
        Object.assign($scope.formData, _registration.getFakeFormData());
      }
    };

    $scope.nextPage = _helper.form.validator(() => {
      if (shouldAskGDPR()) {
        askGDPR();
      }

      Object.assign(cred, $scope.formData);
      $scope.page++;
    });
    const prepareCountriesAndCurrencies = () => {
      $scope.preloader = true;

      _system
        .countries()
        .then((answer) => {
          watchCountryToSetCurrenciesAndPhone();

          $scope.countries = answer.result;

          if (!$scope.formData.country) {
            $scope.formData.country = answer.default;
          }
        })
        .catch((error) => console.error(error))
        .finally(() => ($scope.preloader = false));
    };

    const askGDPR = () => {
      $scope.firstSubmit = false;

      _modal
        .open('gdpr-registration')
        .then(() => ($scope.formData.gdpr_marketing = true))
        .catch(() => {});
    };

    const setRetype = () => {
      if (!$scope.formData.hasOwnProperty('retype')) {
        $scope.formData.retype = $scope.formData.password;
        return;
      }

      formHasOwnRetype = true;
    };

    const clearRetype = () => {
      if (!formHasOwnRetype) {
        delete $scope.formData.retype;
      }
    };

    $scope.lastPage = _helper.form.validator((form) => {
      $scope.preloader = true;
      let preparedWindow = {};

      setRetype();
      const checkIfCanOpenPayment = () => {
        const { max_amount, min_amount, fields } = preSavedData.paysystem;
        const { amount } = preSavedData;

        return !(max_amount < amount || min_amount > amount || fields.length > 0);
      };

      if (checkIfCanOpenPayment() && !countryOrCurrencyChanged) {
        preparedWindow = _paymentWindow.prepared();
        const currentWindow = window;
        currentWindow.focus();
      }
      const preSavedDataPromo = _authWithDeposit.preSavedData().get();

      if (preSavedDataPromo.avatar) {
        $scope.formData.avatar = preSavedDataPromo.avatar;
      }

      Object.assign(cred, $scope.formData);

      if (preSavedDataPromo.enable_trigger_codes) {
        cred.trigger_codes = preSavedDataPromo.trigger_codes;
        delete cred.code && preSavedDataPromo.code;
      }

      if (preSavedDataPromo.code) {
        cred.code = preSavedDataPromo.code;
      }

      if (!preSavedDataPromo.enable_trigger_codes && cred.trigger_codes) {
        delete cred.trigger_codes;
      }

      delete cred.enable_trigger_codes;

      if (!preSavedDataPromo.code) {
        delete cred.code;
      }

      if (cred.country !== preSavedData.country || cred.currency !== preSavedData.currency) {
        countryOrCurrencyChanged = true;
      }

      _account
        .register(cred)
        .then(({ result: { login, prefix } }) => {
          $timeout(() => {
            form.$setSuccessful();
          });

          if (countryOrCurrencyChanged) {
            _popup.open({ name: 'cashbox', content: { warning: true } });
            preparedWindow.close();
            return;
          }

          const preSavedPaysystem = _authWithDeposit.preSavedData().get();

          if (!checkIfCanOpenPayment()) {
            _popup.open({
              name: 'cashbox',
              content: {
                tab: 'deposit',
                step: 'form',
                paySystem: preSavedPaysystem.paysystem,
                amount: preSavedPaysystem.amount,
              },
            });
            preparedWindow.close();
            return;
          }

          _paymentWindow.open(login, preSavedPaysystem, preparedWindow);
          _popup.close();
        })
        .catch(() => {
          preparedWindow.close();
          $scope.page = 1;
          clearRetype();
        })
        .finally(() => ($scope.preloader = false));
    });

    const watchCountryToSetCurrenciesAndPhone = () => {
      $scope.$watch('formData.country', (newVal) => {
        $scope.country = $scope.countries.filter((item) => item.code === newVal)[0] || {};
        $scope.currencyList = _registration.prepareCurrencyList($scope.country.currency) || [];
        const userCurrency = $scope.currencyList.find((currency) => currency.key === preSavedData.currency);
        $scope.formData.currency = userCurrency?.key || $scope.currencyList[0].key;

        if ($scope.formData.phone.length <= 6) {
          $scope.formData.phone = `+${parseInt($scope.country.prefix.replace(/\-/g, ''), 10)}`;
        }
      });
    };

    const shouldAskGDPR = () => {
      const GDPRPage = _registration.config.GDPRPage || 1;
      return $scope.firstSubmit === true && $scope.formData.gdpr_marketing === false && GDPRPage === $scope.page;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
