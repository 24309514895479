import '../../service/configs/registration.js';
import '../../service/domain/auth-with-deposit.js';
(function () {
  'use strict';

  const component = {
    name: 'authWithDepositRegistrationBonus',
  };

  controller.$inject = ['$scope', 'registration', '$timeout', 'authWithDeposit'];

  function controller($scope, _registration, $timeout, _authWithDeposit) {
    const vm = this;
    $scope.preloader = false;
    $scope.hasPromocode = false;
    $scope.promocodeLabelFlag = false;
    $scope.formData = {};
    let defaultAvatar;
    const accountPreSaveData = _authWithDeposit.preSavedData();
    vm.$onInit = () => {
      _registration.addPromotionsToScope($scope);
      const form = accountPreSaveData.get();
      if (form) {
        if (form.enable_trigger_codes) {
          $timeout(() => {
            $scope.choosePromotion('bonus', form.activePromotionIndex);
          });
          return;
        }

        if (!form.enable_trigger_codes && form.code) {
          $scope.choosePromotion('promo');
          $scope.formData.code = form.code;
          $scope.promocodeLabelFlag = true;
          return;
        }

        if (form.enable_trigger_codes === false && !form.code) {
          $timeout(() => {
            $scope.choosePromotion('without');
          }, 1);
          return;
        }

        $scope.choosePromotion('bonus', 0);
      }
    };

    const saveData = (index) => {
      if ($scope.promotions.length && $scope.promotions[index]?.avatar) {
        $scope.formData.avatar = $scope.promotions[index].avatar;
      }

      if(angular.isUndefined(index) && defaultAvatar) {
        $scope.formData.avatar = defaultAvatar;
      }

      const oldPreSavedData = accountPreSaveData.get();
      accountPreSaveData.set({
        ...oldPreSavedData,
        ...$scope.formData,
        activePromotionIndex: $scope.activePromotionIndex,
      });
    };

    $scope.choosePromotion = (type, index) => {
      switch (type) {
        case 'bonus':
          $scope.setActivePromotionByIndex(index);
          $scope.activePromotionIndex = index;
          $scope.formData.code = null;
          $scope.hasPromocode = false;
          $scope.promocodeLabelFlag = false;
          $timeout(() => saveData(index), 0);
          break;
        case 'promo':
          $scope.hasPromocode = true;
          $scope.formData.enable_trigger_codes = false;
          $scope.activePromotion = '';
          $timeout(() => saveData(), 0);
          break;
        case 'without':
          $scope.formData.code = null;
          $scope.hasPromocode = false;
          $scope.formData.enable_trigger_codes = false;
          $scope.activePromotionIndex = null;
          $scope.activePromotion = '';
          $scope.promocodeLabelFlag = false;
          $timeout(() => saveData(), 0);
          break;
        default:
          break;
      }
    };

    vm.$onChanges = () => {
      if (this.promoCode) {
        $scope.promocodeLabelFlag = true;
        $scope.formData.code = this.promoCode;
        $scope.choosePromotion('promo');
      }
      if (this.bonusIndex) {
        $scope.choosePromotion('bonus', this.promoCode);
      }
      if(this.defaultAvatar){
        defaultAvatar = this.defaultAvatar;
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      bonusIndex: '<',
      promoCode: '<',
      defaultAvatar: '<'
    },
  });
})();
