import '../../../service/domain/user.js';
import '../../../service/domain/account.js';
(function () {
  'use strict';

  const component = { name: 'lindaSelfcontrolSession' };
  controller.$inject = ['$scope', 'user', 'account'];

  function controller($scope, _user, _account) {
    $scope.preloader = false;
    $scope.editmode = false;

    const limits = ['130', '21', '22', '23', '24', '25', '26', '27', '28', '29', '210', '211', '212'];

    function reset() {
      const userLimit = _user.profile.session_limit.value;
      let limit;
      if (userLimit && limits.indexOf(userLimit) > -1) {
        limit = userLimit;
      } else if (userLimit && limits.indexOf(userLimit) === -1) {
        limit = '100';
      } else limit = '212';

      $scope.formData = {
        // форма
        period: _user.profile.session_limit.value !== null ? _user.profile.session_limit.value[0] : '1',
        count:
          _user.profile.session_limit.value !== null
            ? parseInt(_user.profile.session_limit.value.substring(1), 10)
            : 10,
        limit: limit,
      };
      $scope.setLimit = _user.profile.session_limit.value !== null; // флаг існування ліміту
    }

    function update(data) {
      $scope.preloader = true;
      _account.sessionLength(data).then(
        (answer) => {
          reset();
          $scope.editmode = false;
          $scope.preloader = false;
        },
        (answer) => {
          reset();
          $scope.preloader = false;
        }
      );
    }

    reset();

    $scope.edit = () => {};

    $scope.back = () => {
      reset();
      $scope.editmode = false;
    };

    $scope.cancel = () => {
      const o = {
        group: 'session_limit',
      };
      $scope.preloader = true;

      _account
        .removeLimit(o)
        .then((a) => {})
        .catch((err) => {})
        .finally(() => {
          $scope.preloader = false;
          reset();
        });
    };

    $scope.submit = (form) => {
      if (form.$valid) {
        const session_limit =
          $scope.formData.limit === '100' ? `${$scope.formData.period}${$scope.formData.count}` : $scope.formData.limit;
        update({
          session_limit: session_limit,
        });
      } else {
        angular.forEach(form.$error, (type) => {
          angular.forEach(type, (field) => {
            field.$setTouched();
          });
        });
      }
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
