import '../../../service/domain/deposit.js';
(function () {
  'use strict';

  const component = { name: 'lindaMultipleQuickDeposit' };

  controller.$inject = ['$scope', 'deposit'];

  function controller($scope, _deposit) {
    $scope.preloader = false;
    $scope.isQuickDeposit = false;

    $scope.paysystem = {};

    $scope.formData = {
      amount: 0,
      paymethod: '',
      paysystem_id: '',
    };

    $scope.selectCard = (cardIndex) => {
      $scope.selectedDeposit = $scope.depositCollection[cardIndex];
      $scope.paysystem = {
        ...$scope.depositCollection[cardIndex].paysystem,
        amount:
          angular.isString($scope.formData.amount)
            ? $scope.formData.amount.split('.')[0] * 1
            : $scope.formData.amount,
        depositIcon: $scope.depositCollection[cardIndex].icon,
      };
    };

    this.$onInit = () => {
      $scope.preloader = true;

      _deposit
        .collection({})
        .then((a) => {
          if (a.result.length) {
            const amount =
              angular.isString(a.result[0].last_transaction.amount)
                ? a.result[0].last_transaction.amount.split('.')[0] * 1
                : a.result[0].last_transaction.amount;

            $scope.depositCollection = a.result;
            $scope.selectCard(0);
            $scope.paysystem = a.result[0].paysystem;
            $scope.formData.amount = amount;
            $scope.paysystem.amount = amount;
            $scope.paysystem.depositIcon = a.result[0].icon;
            $scope.isQuickDeposit = true;
          }
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
