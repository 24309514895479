import user from '../../service/domain/user.js';
import config from '../../service/configs/config.js';
import { SteppedEase, TimelineMax, TweenMax } from 'gsap';
import { cupService } from '../../service/cup/cupService.js';

const componentName = 'cupWithDynamicAchieveMobile';

class Cup {
  static $inject = ['$scope', '$q', 'user', '$timeout', 'config', 'cupService', '$document', '$window'];

  constructor($scope, $q, user, $timeout, config, cupService, $document, $window) {
    const cupGlassImage = `${config.cdn}/${config.siteName}/cup-page/Cup_glass_.png`;
    const cupGoldImage = `${config.cdn}/${config.siteName}/cup-page/Cup_gold.png`;

    const cupGoldImagePromise = cupService.getImage(cupGoldImage);
    const cupGlassImagePromise = cupService.getImage(cupGlassImage);
    const achievementsPromise = cupService.getAchievement({ category: 'cup', count: this.count, order: '' });

    const cupElement = {
      cup: $document[0].querySelector('.cup'),
      wrap: $document[0].querySelector('.cup__wrap'),
      progress: $document[0].querySelector('.cup-progress'),
      progressLine: $document[0].querySelector('.cup-progress__line'),
      back: $document[0].querySelector('.cup__back'),
      front: $document[0].querySelector('.cup__front-img'),
      test: $document[0].querySelector('.cup__front'),
    };

    const progressDuration = 1;
    const twistDuration = 0.25;

    const twistConfig = {
      reversed: true,
      backgroundPosition: '-11808px 0px',
      ease: SteppedEase.config(36),
    };

    const cupConfig = {
      height: '100%',
      ease: SteppedEase.config(100),
    };

    const twistTimeLine = new TimelineMax({ paused: true });
    const progressTimeLine = new TimelineMax({ paused: true });

    const initTwist = () => {
      const twistBackTween = TweenMax.to(cupElement.back, twistDuration, twistConfig);
      const twistFrontTween = TweenMax.to(cupElement.front, twistDuration, twistConfig);

      twistTimeLine.add(twistBackTween, 0);
      twistTimeLine.add(twistFrontTween, 0);
    };

    const initProgressLine = () => {
      const mainProgress = TweenMax.to(cupElement.progress, progressDuration, cupConfig);
      const progressCupProgress = TweenMax.to(cupElement.test, progressDuration, cupConfig);
      const progressLineProgress = TweenMax.to(cupElement.progressLine, progressDuration, cupConfig);

      progressTimeLine.add(mainProgress, 0);
      progressTimeLine.add(progressCupProgress, 0);
      progressTimeLine.add(progressLineProgress, 0);
    };

    $q.all([achievementsPromise, cupGlassImagePromise, cupGoldImagePromise])
      .then((answer) => {
        $scope.preloader = true;
        $scope.collection = answer[0];
        $scope.mainGoal = answer[0].reduce((acc, item) => acc + item.prize, 0);
        $scope.mainAchievement = $scope.collection.slice(-1)[0];
        cupService.currentItem$.next({ item: cupService.currentItem });
        initTwist();
        initProgressLine();
        cupElement.wrap.classList.add('linda-img-loaded');

        progressTimeLine.addLabel('progressCurrent', cupService.progressCup);
        progressTimeLine.addPause('progressCurrent');
        progressTimeLine.play(0);

        $timeout(() => {
          cupService.buildMarks($document[0].querySelectorAll('.cup-mark'));
        }, 0);
      })
      .catch((error) => error)
      .finally(() => {
        $scope.preloader = false;
      });

    function twistCup(e) {
      if (e.gamma <= 30 && e.gamma >= -30) {
        twistTimeLine.progress(e.gamma / 60 + 0.5);
      }
    }

    let movePosition = 0;
    let moveProgress = 0.5;

    function twistCupMove(e) {
      const d = 100;
      if (!movePosition) {
        movePosition = e.touches[0].clientX;
      }
      moveProgress += (e.touches[0].clientX - movePosition) / d;
      movePosition = e.touches[0].clientX;
      if (moveProgress > 1) {
        moveProgress = 1;
      }
      if (moveProgress < 0) {
        moveProgress = 0;
      }
      twistTimeLine.progress(moveProgress);
    }

    this.$onInit = () => {
      cupElement.cup.addEventListener('touchmove', twistCupMove);
      if (config.ios) {
        cupElement.cup.addEventListener('touchend', () => {
          movePosition = 0;
        });
      } else {
        $window.addEventListener('deviceorientation', twistCup);
      }
    };

    this.$onDestroy = () => {
      if (config.ios) {
        cupElement.cup.removeEventListener('touchmove', twistCupMove);
        cupElement.cup.removeEventListener('touchend', () => {
          movePosition = 0;
        });
      } else {
        $window.removeEventListener('deviceorientation', twistCup);
      }
    };
  }
}

app.component(componentName, {
  controller: Cup,
  binding: {
    count: '<',
  },
  template: app.getTU(componentName),
});

