import '../../service/domain/state.js';
import '../../service/domain/popup.js';
import '../../service/domain/user.js';
import '../../service/domain/banner.js';
import '../../service/domain/account.js';
import '../../service/configs/config.js';

(function () {
  'use strict';

  const component = { name: 'lindaGameCashCrab' };

  controller.$inject = ['state', 'popup', 'user', 'banner', 'account', 'config', '$window'];

  function controller(_state, _popup, _user, _banner, _account, _config, $window) {
    if (_config.feature?.cash_crab?.visible === false) {
      _state.goto('home');
      return;
    }

    switch (true) {
      case _user.profile.cash_crab &&
        !_user.profile.cash_crab.cash_crab_attempts &&
        !Boolean($window.localStorage.getItem('cash-crab-onboarding-viewed')):
        _popup.open({ name: 'claw-need-first-deposit' });
        $window.localStorage.setItem('cash-crab-onboarding-viewed', 'true');
        break;
      case _user.profile.cash_crab &&
        !_user.profile.cash_crab.cash_crab_attempts &&
        !_user.profile.cash_crab.assigned_today:
        _popup.open({ name: 'claw-need-deposit' });
        break;
      case _user.profile.cash_crab &&
        !_user.profile.cash_crab.cash_crab_attempts &&
        _user.profile.cash_crab.assigned_today:
        _popup.open({ name: 'claw-wait' });
        break;
      default:
        break;
    }

    if (_user.profile.cash_crab && _user.profile.cash_crab.cash_crab_attempts <= 0) {
      _state.goto('home');
      return;
    }

    let prizes = [];

    _banner
      .collection({ place: 'cash_crab', out: 'images,text' })
      .then((data) => {
        prizes = data.result;
      })
      .catch(() => {})
      .finally(() => {});

    const listener = (message) => {
      switch (message.data.type) {
        case 'claw-result':
          const win = message.data.data.game_result;
          const alias = message.data.data.prize_id;
          const prize = prizes.find((i) => i.text[0] === alias);
          if (prize && win) {
            _popup.open({ name: 'claw-win', content: { prize } });
          } else {
            _popup.open({ name: 'claw-try-again' });
          }
          _account.info({ nocache: true });
          break;
        case 'claw-prizes':
          _popup.open({ name: 'claw-prizes', content: { prizes } });
          break;
        case 'claw-goto':
          _state.goto(message.data.alias);
          break;
        default:
          break;
      }
    };
    $window.addEventListener('message', listener);
    this.$onDestroy = () => {
      $window.removeEventListener('message', listener);
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
