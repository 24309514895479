import '../../service/domain/paysystem.js';
import '../../service/domain/auth-with-deposit.js';
(function () {
  'use strict';

  const component = {
    name: 'authWithDepositPaysystems',
  };

  controller.$inject = ['$scope', 'paysystem', 'authWithDeposit'];

  function controller($scope, _paysystem, _authWithDeposit) {
    const vm = this;
    const authBufferData = _authWithDeposit.preSavedData();
    $scope.paymentList = [];
    vm.$onInit = () => {
      $scope.preloader = true;
      const config = {
        currency: authBufferData.get().currency,
        country: authBufferData.get().country,
        type: 'deposit',
      };
      _paysystem
        .banking(config)
        .then((answer) => {
          $scope.paymentList = answer.result.map((item) => Object.assign(item, { purpose: config.type }));
        })
        .catch((error) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };

    $scope.choosePaysystem = (paysystem) => {
      authBufferData.set({ ...authBufferData.get(), paysystem });
      const parentScope = $scope.$parent;
      parentScope.paysystemStep = false;
      if (!!parentScope.hasRememberedUser) {
        parentScope.hideLogin = false;
        return (parentScope.loginStep = true);
      }
      parentScope.registrationStep = true;
      parentScope.hideRegistartion = false;
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
