import {bonus} from '../../../service/domain/bonus.js';

(function () {
  controller.$inject = ['$scope', 'bonus'];

  function controller($scope, _bonus) {
    $scope.bonus = {};
    $scope.preloader = false;

    $scope.activate = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        _bonus.activate($scope.bonus, $scope.bonus?.game?.alias)
          .finally(() => {
            $scope.preloader = false
          });
      }
    };

    $scope.cancel = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        _bonus.cancel($scope.bonus)
          .finally(() => {
            $scope.preloader = false
          });
      }
    };

    this.$onChanges = () => {
      $scope.bonus = this.bonus;
    };
  }

  new Controller('bonusSpinItem', controller, {
    bonus: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanBonusSpinItem${i}`, 'bonusSpinItem');
  });
})();
