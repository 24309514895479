import '../../service/domain/state.js';
import '../../service/rx/system$.js';
import '../../service/configs/config.js';
(function () {
  'use strict';

  const component = { name: 'lindaWithdrawalPaymentCallback' };

  controller.$inject = ['$scope', '$state', '$interval', 'system$', 'state', 'config', '$window'];

  function controller($scope, $state, $interval, _system$, _state, _config, $window) {
    let timerSeconds = 20;
    const webView = _config.browser.webView;
    $scope.timer = `00:${timerSeconds}`;
    $scope.action = $state.params.status;
    makeTimer();

    if (isOpenedInFrame()) {
      $window.parent.postMessage(`transaction-${$scope.action}`, '*');
      $scope.close = function () {
        $window.top.postMessage('close', '*');
      };
      $scope.openChat = function () {
        $window.top.postMessage('open-chat', '*');
      };
      return;
    }

    if (!isWebView() || isOpenedInWindow()) {
      $window.parent.postMessage(`transaction-${$scope.action}`, '*');
      $window.opener?.parent.postMessage(
        {
          action: 'transaction',
          status: $scope.action,
        },
        '*',
      );

      $scope.close = function () {
        if ($window.opener && !$window.opener.closed && !webView) {
          $window.opener.parent.postMessage('close', '*');
        } else {
          _state.goto('home');
        }
      };

      $scope.openChat = function () {
        if ($window.opener && !$window.opener.closed && !webView) {
          $window.opener.parent.postMessage('open-chat', '*');
        } else {
          _system$.next({ action: 'open chat' });
        }
      };
      return;
    }

    function makeTimer() {
      const timer = $interval(() => {
        if (timerSeconds >= 0) {
          const formattedTimeWithSeconds = timerSeconds.toString().padStart(2, '0');
          $scope.timer = `00:${formattedTimeWithSeconds}`;
          timerSeconds--;
        } else {
          $interval.cancel(timer);
          $scope.close();
        }
      }, 1000);
    }

    function isWebView() {
      return webView;
    }

    function isOpenedInWindow() {
      return $window.opener && !$window.opener.closed;
    }
    function isOpenedInFrame() {
      return $window.self !== $window.top;
    }
    $scope.close = function () {
      _state.goto('home');
    };

    $scope.openChat = function () {
      _system$.next({ action: 'open chat' });
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
