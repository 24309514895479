import '../../service/configs/config.js';
import '../../service/domain/payment.js';
(function () {
  controller.$inject = ['config', 'system$', 'payment', '$window', '$interval'];

  function controller(_config, _system$, _payment, $window, $interval) {
    let paymentWindow = {};

    return {
      prepared(login = null) {
        if (paymentWindow?.closed === false) {
          return paymentWindow.focus();
        }

        const width = 800;
        const height = 500;
        const left = ($window.innerWidth - width) / 2;
        const top = ($window.innerHeight - height) / 2;
        function messageHandler({ data }) {
          if (data === 'close') {
            paymentWindow.close();
            paymentWindow = {};
            return;
          }

          if (data === 'open-chat') {
            paymentWindow.close();
            paymentWindow = {};
            _system$.next({ action: 'open chat' });
            return;
          }
        }

        $window.onmessage = messageHandler;

        // eslint-disable-next-line angular/interval-service
        const checker = setInterval(() => {
          if (paymentWindow.closed) {
            paymentWindow = {};
            clearInterval(checker);
          }
        }, 100);

        paymentWindow = _config.browser.webView
          ? $window
          : $window.open(
              `/preloader.html?login=${login}`,
              'depositLogin',
              `height=${height},width=${width},left=${left},top=${top}`
            );

        return paymentWindow;
      },

      open(login, paymentData, preparedWindow) {
        preparedWindow.focus();

        _payment
          .deposit({
            amount: paymentData.amount,
            paysystem_id: paymentData.paysystem.id,
            paymethod: paymentData.paysystem.rules.system,
          })
          .then(({ result: { url, post, method } }) => {
            preparedWindow.document.location = url;
          })
          .catch((error) => {
            preparedWindow.document.location = '/' + _config.currentLang + `/payment/fail?login=${login}`;
          });
      },
    };
  }

  app.factory('paymentWindow', controller);
})();
